/* eslint-disable react/button-has-type */
import React from 'react'

import * as styles from './Button.module.scss'

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  className,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      type={type}
      className={[
        className,
        styles.root,
      ].join(' ')}
    >
      {children}
    </button>
  )
}
