import React from 'react'

import * as styles from './Video.module.scss'

import { VIDEO_ID } from '../../consts'

export const Video: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <div className={styles.maxWidth}>
          <div className={styles.wrapper}>
            <iframe
              className={styles.iframe}
              allowFullScreen
              src={`https://www.youtube.com/embed/${VIDEO_ID}?rel=0&controls=0&playlist=${VIDEO_ID}&loop=1`}
              title="YouTube video player"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
