/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Translate } from 'react-localize-alias'
import { PageDirection } from '../../../../view/Root'

import * as styles from './MapMarker.module.scss'

interface MapMarker extends PageDirection {
  lat: number
  lng: number
  url: string
}

export const MapMarker: React.FC<MapMarker> = ({ lat, lng, url, dir }) => (
  <>
    <a
      className={styles.root}
      href={url}
      target="_blank"
      rel="noreferrer"
      // @ts-expect-error
      lat={lat}
      lng={lng}
    >
      <div className={styles.tooltip} dir={dir}>
        <h3 className={styles.name}>
          <Translate id="label" />
        </h3>
        <p className={styles.address}>
          <Translate id="address" />
        </p>
      </div>
    </a>
  </>
)
