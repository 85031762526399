import React from 'react'

export const FlagGB: React.FC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="3" width="14" height="10" rx="2" fill="white" />
    <mask id="mask0_1614_19171" maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="10">
      <rect x="1" y="3" width="14" height="10" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0_1614_19171)">
      <rect x="1" y="3" width="14" height="10" fill="#0A17A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33865 9.6661L-0.384766 12.8521L0.360825 13.9575L6.33529 9.92764V13.6661H9.66862V9.92761L15.6438 13.9579L16.3894 12.8525L11.6653 9.6661H15.002V6.33276H11.6649L16.3889 3.1464L15.6433 2.04102L9.66862 6.07098V2.33276H6.33529V6.07096L0.361328 2.04147L-0.384263 3.14686L4.33904 6.33276H1.00195V9.6661H4.33865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9453 1.81692C17.0464 1.97072 17.0038 2.17741 16.85 2.27859L10.5173 6.44469C10.3635 6.54587 10.1568 6.50321 10.0556 6.34942C9.95445 6.19562 9.9971 5.98892 10.1509 5.88774L16.4836 1.72164C16.6374 1.62046 16.8441 1.66312 16.9453 1.81692Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9593 13.862C17.0622 13.7093 17.0219 13.5022 16.8692 13.3993L11.1923 9.57284C11.0396 9.46995 10.8325 9.51029 10.7296 9.66294C10.6267 9.8156 10.667 10.0228 10.8197 10.1257L16.4966 13.9521C16.6492 14.055 16.8564 14.0146 16.9593 13.862Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.19623 1.97862C-1.09334 1.82596 -0.88618 1.78561 -0.733518 1.88849L5.18799 5.87921C5.34065 5.9821 5.38101 6.18926 5.27812 6.34192C5.17524 6.49458 4.96808 6.53494 4.81541 6.43205L-1.1061 2.44133C-1.25876 2.33844 -1.29911 2.13128 -1.19623 1.97862Z"
        fill="#DB1F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.1976 14.3394C-1.09586 14.4928 -0.889005 14.5347 -0.73558 14.433L5.82818 10.0802C5.98161 9.97843 6.0235 9.77157 5.92176 9.61815C5.82001 9.46472 5.61316 9.42283 5.45973 9.52457L-1.10403 13.8774C-1.25745 13.9791 -1.29935 14.186 -1.1976 14.3394Z"
        fill="#DB1F35"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 9H7V13H9V9H15V7H9V3H7V7H1V9Z" fill="#E6273E" />
    </g>
  </svg>
)
