import React from 'react'
import GoogleMapReact from 'google-map-react'

import { WhatsAppButton } from '../WhatsAppButton'

import * as styles from './Map.module.scss'
import { mapStyles } from './GoogleMapStyles'
import { MapMarker } from './MapMarker/MapMarker'
import { PageDirection } from '../../../view/Root'
import { MAP_API_KEY, MAP_LOCATION_URL } from '../../consts'

const mapData = {
  center: {
    lat: 25.072755,
    lng: 55.230322,
  },
  marker: {
    lat: 24.981711,
    lng: 55.146446,
  },
  zoom: 11,
  key: MAP_API_KEY,
  url: MAP_LOCATION_URL,
}

export const Map: React.FC<PageDirection> = ({ dir }) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.map}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapData.key }}
            defaultCenter={mapData.center}
            defaultZoom={mapData.zoom}
            options={{ styles: mapStyles, disableDefaultUI: true }}
          >
            <MapMarker lat={mapData.marker.lat} lng={mapData.marker.lng} url={mapData.url} dir={dir} />
          </GoogleMapReact>
        </div>

        <WhatsAppButton className={styles.button} />
      </div>
    </div>
  )
}
