/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from 'gatsby'
import { Translation } from 'react-localize-alias'

export const getTranslations = (): Record<string, Translation> => {
  const data = useStaticQuery(graphql`
    {
      ar {
        internal {
          content
        }
      }

      en {
        internal {
          content
        }
      }

      ru {
        internal {
          content
        }
      }
    }
  `)

  return {
    ar: JSON.parse(data.ar.internal.content),
    en: JSON.parse(data.en.internal.content),
    ru: JSON.parse(data.ru.internal.content),
  }
}
