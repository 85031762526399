/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { HTMLAttributes, useState } from 'react'
import { Link } from 'gatsby'
import { withLocalization } from 'react-localize-alias'
import cn from 'classnames'

import * as styles from './LangSwitcher.module.scss'

import { FlagAE, FlagGB, FlagRU } from './LangFlags'

const languages = {
  en: { label: 'English language', icon: <FlagGB /> },
  ar: { label: 'اللغة العربية', icon: <FlagAE /> },
  ru: { label: 'Русский язык', icon: <FlagRU /> },
}

interface LangSwitcherProps {
  variant: 'dropdown' | 'dropup'
  className: string
}

export const LangSwitcher: React.FC<LangSwitcherProps> = withLocalization(props => {
  const { config, variant, className } = props

  const [open, setOpen] = useState(false)

  const activeLanguage = languages[config.activeLanguage as keyof typeof languages]

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <button
      className={cn(styles.root, styles.option, styles.active, open && styles[variant], className)}
      type="button"
      onClick={toggleOpen}
    >
      <div className={styles.label}>{activeLanguage.label}</div>
      <div className={styles.flag}>{activeLanguage.icon}</div>

      <div className={cn(styles.options, styles[variant], open && styles.show)}>
        {Object.entries(languages).map(([code, { label, icon }]) => (
          <Link
            className={cn(styles.option, config.activeLanguage === code && styles.active)}
            key={code}
            to={`/${code}`}
          >
            <div className={styles.label}>{label}</div>
            <div className={styles.flag}>{icon}</div>
          </Link>
        ))}
      </div>
    </button>
  )
})
