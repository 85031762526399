import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Translate } from 'react-localize-alias'
import cn from 'classnames'

import * as styles from './Overview.module.scss'

import { WhatsAppButton } from '../WhatsAppButton'
import { PageDirection } from '../../../view/Root'

const Overview: React.FC<PageDirection> = ({ dir }) => {
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <h1 className={styles.title}>
          <Translate id="overview.title" />
        </h1>
        <h2 className={styles.description}>
          <Translate id="overview.description" />
        </h2>
        <WhatsAppButton />
      </div>
      <div className={cn(styles.image, styles[dir])}>
        {/* Линия с левого края картинки, которая перекрывает ее артефакты.
        При попытки пофиксить артефакты, вес картинки увеличивается в 8 раз и не сжимается */}
        <div className={styles.line} />
        <StaticImage src="../../../../assets/images/overview.png" alt="Truck with boxes" />
      </div>
    </div>
  )
}

export default Overview
