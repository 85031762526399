/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { getTranslate } from 'react-localize-alias'
import { Helmet } from 'react-helmet'

import * as styles from './AppHeader.module.scss'

import { Logo } from '../Logo'
import { LangSwitcher } from '../LangSwitcher'
import { PageContext } from '../../../../pages'

interface AppHeaderProps {
  lang: PageContext['langCode']
}

const AppHeader: React.FC<AppHeaderProps> = ({ lang }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang }}>
        <meta charSet="utf-8" />
        <title>{getTranslate('title')}</title>
        <link rel="canonical" href="https://safebox.ae/" />
        <meta name="description" content={getTranslate('description') as string} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <header className={styles.root}>
        <div className={styles.header}>
          <Logo />
          <LangSwitcher variant="dropdown" />
        </div>
      </header>
      <div style={{ height: 60 }} />
    </>
  )
}

export default AppHeader
