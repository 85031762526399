import React from 'react'
import { Translate } from 'react-localize-alias'
import cn from 'classnames'

import * as styles from './WhatsAppButton.module.scss'

import { Button, ButtonProps } from '../../UIkit/Button'
import whatsAppIcon from '../../../../assets/images/whatsApp.svg'

export const WhatsAppButton: React.FC<ButtonProps> = ({ className }) => {
  const openWhatsApp = () => {
    window.open('https://wa.me/971585784308', '_blank')
  }

  return (
    <Button className={cn(styles.root, className)} onClick={openWhatsApp}>
      <Translate id="whatsapp" />
      <img src={whatsAppIcon} alt="WhatsApp" />
    </Button>
  )
}
