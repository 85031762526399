import React from 'react'

export const FlagAE: React.FC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.25" y="6.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5" />
    <mask id="mask0_1434_1273" maskUnits="userSpaceOnUse" x="2" y="6" width="28" height="20">
      <rect x="2.25" y="6.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5" />
    </mask>
    <g mask="url(#mask0_1434_1273)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 12.6667H30V6H10V12.6667Z" fill="#12833B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 25.9992H30V19.3325H10V25.9992Z" fill="#262626" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 26H10V6H2V26Z" fill="#FF323E" />
    </g>
  </svg>
)
