import React from 'react'
import { PageProps as PagePropsGatsby } from 'gatsby'
import { ProviderLocalization } from 'react-localize-alias'

import '../assets/styles/index.scss'

import { RootController } from '../app/view/Root'

export interface PageContext {
  langCode: string
  dir: 'ltr' | 'rtl'
}
export type PageProps = PagePropsGatsby<unknown, PageContext, unknown, unknown>

const IndexPage: React.FC<PageProps> = props => {
  return (
    <ProviderLocalization>
      <RootController {...props} />
    </ProviderLocalization>
  )
}

export default IndexPage
