import React from 'react'
import { Translate } from 'react-localize-alias'

import * as styles from './Conditions.module.scss'

const Conditions: React.FC = () => {
  const conditions = [
    'conditions.1',
    'conditions.2',
    'conditions.3',
  ]

  return (
    <div className={styles.root} id="conditions">
      <div className={styles.title}>
        <Translate id="conditions.title" />
      </div>
      <div className={styles.block}>
        {conditions.map((text, idx) => (
          <div key={text} className={styles.condition}>
            <div className={styles.counter}>
              <Translate id={`conditions.counter.${idx + 1}`} />
            </div>
            <span className={styles.text}>
              <Translate id={text} />
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Conditions
