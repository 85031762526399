import React from 'react'
import { Translate } from 'react-localize-alias'

import * as styles from './Logo.module.scss'

import LogoSymbol from '../../../../assets/images/logo.inline.svg'

export const Logo = () => (
  <div className={styles.root}>
    <LogoSymbol className={styles.img} />
    <Translate id="label" />
  </div>
)
