/* eslint-disable max-len */
import React from 'react'
import { Translate } from 'react-localize-alias'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './Benefits.module.scss'

const Benefits: React.FC = () => {
  return (
    <div className={styles.wrapper} id="benefit">
      <div className={styles.root}>
        <div>
          <h4 className={styles.title}>
            <Translate id="benefit.title" />
          </h4>
          <div className={styles.benefits}>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/truck.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.truck" />
              </div>
            </div>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/doorOpen.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.door" />
              </div>
            </div>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/windy.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.windy" />
              </div>
            </div>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/houseSecurity.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.security" />
              </div>
            </div>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/heartBox.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.heart" />
              </div>
            </div>
            <div className={styles.benefit}>
              <StaticImage src="../../../../assets/images/wallet.svg" alt="" height={40} />
              <div>
                <Translate id="benefits.wallet" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.text}>
          <Translate id="benefit.text.1" />
          <br />
          <br />
          <Translate id="benefit.text.2" />
        </div>
      </div>
    </div>
  )
}

export default Benefits
