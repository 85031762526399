import React from 'react'
import { initLocalization } from 'react-localize-alias'

import AppHeader from '../../share/compoments/AppHeader'
import Benefits from '../../share/compoments/Benefits'
import Conditions from '../../share/compoments/Conditions'
import Overview from '../../share/compoments/Overview'
import getTranslations from '../../share/service/translations'
import AppFooter from '../../share/compoments/AppFooter'
import { Video } from '../../share/compoments/Video'
import { Map } from '../../share/compoments/Map'
import { PageProps } from '../../../pages'

export interface PageDirection {
  dir: 'ltr' | 'rtl'
}

export const RootController: React.FC<PageProps> = ({ pageContext }) => {
  const translations = getTranslations()

  initLocalization({
    languages: [{ code: 'en', name: 'English' }],
    translations,
    options: {
      defaultLanguage: pageContext.langCode,
      // eslint-disable-next-line no-console
      onMissingTranslation: data => console.log(data),
    },
  })

  return (
    <div dir={pageContext.dir}>
      <AppHeader lang={pageContext.langCode} />

      <div style={{ maxWidth: 1584, margin: '0 auto', padding: '0 6px' }}>
        <Overview dir={pageContext.dir} />
        <Conditions />
        <Benefits />
      </div>
      <Video />
      <Map dir={pageContext.dir} />
      <AppFooter />
    </div>
  )
}
