import React from 'react'
import { Translate } from 'react-localize-alias'
import { LangSwitcher } from '../LangSwitcher'
import { Logo } from '../Logo'

import * as styles from './AppFooter.module.scss'

const AppFooter: React.FC = () => {
  return (
    <footer className={styles.root}>
      <div className={styles.main}>
        <Logo />

        <div className={styles.info}>
          <div className={styles.address}>
            <Translate id="address.footer" />
          </div>

          <div className={styles.contacts}>
            <Translate id="contact.tel" />
            <br />
            <Translate id="contact.email" />
          </div>
        </div>
      </div>

      <LangSwitcher className={styles.langs} variant="dropup" />
    </footer>
  )
}

export default AppFooter
