import React from 'react'

export const FlagRU: React.FC<React.SVGAttributes<SVGSVGElement>> = props => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2.25" y="6.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#CFD0DC" strokeWidth="0.5" />
    <mask id="mask0_1433_952" maskUnits="userSpaceOnUse" x="2" y="6" width="28" height="20">
      <rect x="2.25" y="6.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5" />
    </mask>
    <g mask="url(#mask0_1433_952)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 19.3339H30V12.6672H2V19.3339Z" fill="#0C47B7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 25.9992H30V19.3325H2V25.9992Z" fill="#E53B35" />
    </g>
  </svg>
)
